<template >
  <!-- 底部第一行容器 -->
  <el-card class="force-directed"
    :body-style="{ padding: '10px' }">
    <form action="/network" style="color: #F0EBE1;">
        Distance: <input style="position:relative;top:3px;" type="range" id="lengthscale" min="5" max="400" v-model="lengthdata">
        Charge: <input style="position:relative;top:3px;" type="range" id="chargescale" min="10" max="2000" v-model="chargedata">
    </form>
    <!-- 搜索框 -->
    <!-- <el-select v-show="window_width>768" class="select_box" v-model="select" filterable placeholder="影人|影片" @change="serach_item_change">
      <el-option label="影人" value="影人"></el-option>
      <el-option label="影片" value="影片"></el-option>
    </el-select>
    <el-select v-show="window_width>768" class="input_style" v-model="search_content" filterable :placeholder="`请输入内容`" @change="handle_select" ref="elinput">
      <el-option v-for="(director, index) in search_option" :key="index" :label="director.m_name || director.f_name"
        :value="director.m_name || director.f_name"></el-option>
    </el-select> -->
 
    <!-- <div v-show="window_width<768" align='left' style="font-weight:bold;margin-left:2vw;color:#e2a253;font-size:15px;margin:-1.5vw 0 2vw -0.5vw">影人关系图</div> -->
    <div id="svgbox" style=" width: 100%;height:100%">
      <el-row>
        <el-col :span="4">
          <div id="asidetip" style="height:100%">
            <br />
          </div>
        </el-col>
        <el-col :span="20">
          <svg id="forceDirected" style="width: 100%;"></svg>
        </el-col>
      </el-row>
    </div>
    <div id="svgdataurl"></div>
    <canvas style="display:none"></canvas>
 
  </el-card>
</template>

<script>
import * as d3 from "d3";
import * as cloud from "d3-cloud";
import * as $ from "jQuery";
import { loadHistory } from "../../../utils/store";
import service from "../../utils/request";

export default {
  components: {},
  name: "PicForce",
  props: {
  },
  data () {
    return {
      // 本地切换2/2
      path: "https://api.movie.yingshinet.com",
      // path: "http://127.0.0.1:5004",
      nodes: {},
      links: {},
      dialog: false,
      loading: false,
      nodesclass: ["影人", "影片"],
      nodeclass1: "影人",
      nodeclass2: "影片",
      movies: [],
          edgelength:200,
      edgewidth:200,
      chargedata:500,
      lengthdata:80,
      directors: [],
      addRelation1_select: "",
      addRelation2_select: "",
      relation_content: "",
      relation_memo: "",
      // 搜索框
      search_content: "",
      search_option: [],
      select: "影人",
      keyJson: {
      },
      window_width:window.screen.width,
    };
  },

  methods: {
    // 词云图起步获得初始数据
    getData () {
      // this.axios.post(this.path + "/figures/relation", this.keyJson).then((response) => {
      //   console.log(JSON.stringify(response.data));
      //   this.nodes = response.data.nodes
      //   this.links = response.data.links
      //   this.initforcedirect()
      // });
      const jsonFilePath = "/static/homepage_force.json";
      // 发送GET请求以获取本地JSON数据
      this.axios.get(jsonFilePath).then((response) => {
        console.log(response.data);
        this.nodes = response.data.nodes;
        this.links = response.data.links;
        this.initforcedirect();
      });
    },
    // 初始化力导向图的svg尺寸和绑定
    initforcedirect () {
      this.drawForceDirect();
      this.$nextTick(function () {
        document.querySelectorAll(".clickNodes").forEach((item) => {
          // console.log(item.classList[1].slice(2),"ss")//得到id
          item.addEventListener("dblclick", () => {
            // 词云图词汇内容
            const id = item.classList[1].slice(2)
            const name = item.classList[2].slice(4)
            if (id < 10000) {
              //搜索
              const keyJson = {
                type: "figure"
              };
              keyJson.search = name
              this.keyJson = keyJson,
                this.getData()

            } else {
              // this.$router.push('/video?id=' + (id - 10000))跳转
              //搜索
              const keyJson2 = {
                type: "movie"
              };
              keyJson2.search = name,
                this.keyJson = keyJson2,
                this.getData()

            }
            // let keyword = item.textContent;
          });
        });
      });
    },
    drawForceDirect () {
      let marge = { top: 60, bottom: 20, left: 0, right: 60 }
      let svg = d3.select("#forceDirected");
      svg.selectAll("*").remove();
      let width = $(".force-directed").width() * 1;
      let height = $(".force-directed").height() - 20;
      svg
        .attr("width", width)
        .attr("height", height);
      let g = svg.append('g')
        .attr('transform', 'translate(' + marge.top + ',' + marge.left + ')')
      const img_h = 50;
      const img_w = 50;
      const radius = 23;
      let nodes = this.nodes
      let edges = this.links
      // 新建一个力导向图
      let forceSimulation = d3.forceSimulation()
        .force('link', d3.forceLink().id(function (d) { //根据指定的 links 以及默认参数创建一个弹簧力模型。如果没有指定 links 则默认为空数组。
          return d.id
        }).distance(function (d) { //连线数据 distance为连线的距离设置
          return 40
        }))
        .force('charge', d3.forceManyBody().strength(-800))//节点间的作用力
        .force('collide', d3.forceCollide().radius(() => 30))//collide 为节点指定一个radius区域来防止节点重叠。
        .force('center', d3.forceCenter())


      // 生成节点数据
      forceSimulation.nodes(nodes)
        .on('tick', ticked)
      // 生成边数据
      forceSimulation.force('link')
        .links(edges)

      // 设置图形中心位置
      forceSimulation.force('center')
        .x(width / 2 - 200)
        .y(height / 2)
     d3.select("#chargescale").on("change", function() {
        console.log(this.value)
        forceSimulation.force("charge",d3.forceManyBody().strength(-this.value))
             })
     d3.select("#lengthscale").on("change", function() {
        console.log(this.value)
        forceSimulation.force("link").distance(this.value)
             })

      //提示框部分
      let tooltip = d3.selectAll("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0.0);
      //箭头
      let marker =
        svg.append("marker")
          .attr("id", "arrow")
          .attr("markerUnits", "userSpaceOnUse")
          .attr("viewBox", "0 -5 10 10")//坐标系的区域
          .attr("refX", 28)//箭头坐标
          .attr("refY", 0)
          .attr("markerWidth", 12)//标识的大小
          .attr("markerHeight", 12)
          .attr("orient", "auto")//绘制方向，可设定为：auto（自动确认方向）和 角度值
          .attr("stroke-width", 2)//箭头宽度
          .append("path")
          .attr("d", "M0,-5L10,0L0,5")//箭头的路径
          .attr('fill', '#e2a253');//箭头颜色


      //非基于路径的力导向
      let links = g.append('g')
        .selectAll('line')
        .data(edges)
        .enter()
        .append('line')
        .attr('stroke', function (d, i) {
          // return colorScale(i)
          return "#e2a253"
        })
        .attr('stroke-width', 1)


      // 绘制基于路径的边
      let paths = g.append('g')
        .selectAll('path')
        .data(edges)
        .enter()
        .append('path').attr("id", function (d, i) {
          return "edgepath" + i;
        }).attr("class", "edges")
        .attr('stroke', function (d, i) {
          // return colorScale(i)
          return "#e2a253"
        }).attr("marker-end", "url(#arrow)")
        .attr('stroke-width', 1).attr("fill", "none")

      // 边上的文字
      let linksText = g.append('g')
        .selectAll('text')
        .data(edges)
        .enter()
        .append('text').append('textPath')//基于路径时用
        .attr("text-anchor", "middle").attr("startOffset", "50%")
        .attr("fill", "#F0EBE1")
        .attr('xlink:href', (d, i) => {
          // console.log("s");
          return "#edgepath" + i;
        }) //基于路径时用
        .text(function (d) {
          return d.relation
        })

      // 创建分组
      let gs = g.selectAll('.circleText')
        .data(nodes)
        .enter()
        .append('g')
        .attr('transform', function (d) {
          let cirX = d.x
          let cirY = d.y
          return 'translate(' + cirX + ',' + cirY + ')'
        }).attr("class", (d, i) => {
          return "clickNodes" + " id" + d.id + " name" + d.name
        })
        .call(d3.drag()
          .on('start', started)
          .on('drag', dragged)
          .on('end', ended)
        )
      // 绘制节点
      let nodescircle = gs.append('circle')
        .attr('r', radius)
        .attr("fill", function (d, i) {
          //创建圆形图片
          // console.log(svg)
          var defs = svg.append("defs").attr("class", "imgdefs");
          var catpattern = defs.append("pattern")
            .attr("id", "catpattern" + i)
            .attr("height", 1)
            .attr("width", 1);
          // console.log(catpattern)
          catpattern.append("image")
            .attr("x", - (img_w / 2 - radius + 5.8))
            .attr("y", - (img_h / 2 - radius + 3.5))
            .attr("width", img_w + 11)
            .attr("height", img_h + 6)
            // .attr("xlink:href",()=>{ console.log(d.image,"ss") ;return d.image})
            .attr("xlink:href", d.image);
          return "url(#catpattern" + i + ")";
        })
      // .attr('fill', function (d, i) {
      //   return colorScale(i)
      // })
      // Draw text
      // 绘制文字
      gs.append('text')
        // .attr('x', d => -d.name.length * 5)
        .attr("text-anchor", "middle").attr("startOffset", "50%")
        .attr("fill", "#F0EBE1")
        .attr("stroke", "#E2A253")
        .attr("stroke-width", 0.5)
        .attr('y', -25)
        .attr('dy', 0)
        .text(function (d) {
          return d.name
        })
      // ticked
      function ticked () {
        paths.attr("d", (d) => { //基于路径
          let dx = d.target.x - d.source.x;//增量
          let dy = d.target.y - d.source.y;
          return "M" + d.source.x + "," + d.source.y + "L" + d.target.x + "," + d.target.y;
        })
        // links //直线非基于路径
        //   .attr('x1', function (d) { return d.source.x })
        //   .attr('y1', function (d) { return d.source.y })
        //   .attr('x2', function (d) { return d.target.x })
        //   .attr('y2', function (d) { return d.target.y })
        // linksText //非基于路径
        //   .attr('x', function (d) { return (d.source.x + d.target.x) / 2 })
        //   .attr('y', function (d) { return (d.source.y + d.target.y) / 2 })
        gs
          .attr('transform', function (d) { return 'translate(' + d.x + ',' + d.y + ')' })
      }
      // 设置drag
      function started (e, d) {
        if (!e.active) {
          forceSimulation.alphaTarget(0.8).restart() // Set the attenuation coefficient to simulate the node position movement process. The higher the value, the faster the movement. The value range is [0, 1] // 设置衰减系数，对节点位置移动过程的模拟，数值越高移动越快，数值范围[0, 1]
        }
        d.fx = e.x
        d.fy = e.y
      }
      function dragged (e, d) {
        asidetip
          .style("opacity", 0)
          .html("<br/>");
        d.fx = e.x
        d.fy = e.y
      }
      function ended (e, d) {
        if (!e.active) {
          forceSimulation.alphaTarget(0)
        }
        d.fx = null
        d.fy = null
      }
      //asidetip
      let asidetip = d3
        .select("#asidetip")
        .style("opacity", 0)
        // .style("position", "absolute")
        .attr("class", "asidetip")
        // .style("background-color", "black")
        .style("color", "#F0EBE1")
        .style("padding-top", "5px")
        .style("border-radius", "5px")

      const mouseover = function (e, d) {
        // console.log(e.layerY);
        asidetip.html("" + (d.brief || d.intro))
          .style("opacity", 0.8)
        // .style("z-index", 1)
        // if (e.layerY < 240) {
        //   asidetip
        //     .style("left", -10 + "px")
        //     .style("top", 410 + "px");
        // } else {
        //   asidetip
        //     .style("left", -10 + "px")
        //     .style("top", -10 + "px");
        // }
      }
      const mousemove = function (e, d) {
        asidetip.style("opacity", 0.8)
        // if (e.layerY < 240) {
        //   asidetip
        //     .style("left", -10 + "px")
        //     .style("top", 410 + "px");
        // } else {
        //   asidetip
        //     .style("left", -10 + "px")
        //     .style("top", -10 + "px");
        // }
      }
      const mouseout = function (e, d) {
        asidetip
          .style("opacity", 0)
          .html("<br/>");
        // console.log("mouseout");
      }
      nodescircle.on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseout", mouseout)

    },
    downloadPhoto (form = "svg") {
      // d3.select("#photo-download").on("click", function () {
      // 导出最基础的svg
      let html = d3.select("#forceDirected")
        .attr("version", 1.1)
        .attr("xmlns", "http://www.w3.org/2000/svg")
        .attr("xmlns:xlink", "http://www.w3.org/1999/xlink")
        .node().parentNode.innerHTML;
      let imgsrc = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(html)));
      // 从 String 对象中创建一个 base-64 编码的 ASCII 字符串，其中字符串中的每个字符都被视为一个二进制数据字节。
      let image = new Image;
      image.src = imgsrc;
      if (form === "png") {
        let img = '<img src="' + imgsrc + '">';
        d3.select("#svgdataurl").html(img);
        let boxWidth = document.querySelector(".force-directed").offsetWidth
        let boxHeight = document.querySelector(".force-directed").offsetHeight
        let canvas = document.querySelector("canvas"),
          context = canvas.getContext("2d");
        canvas.height = boxHeight;//canvas高度变化时清空画布
        canvas.width = boxWidth;
        image.onload = function () {
          context.drawImage(image, 0, 0);
          let canvasdata = canvas.toDataURL("image/png");
          let pngimg = '<img src="' + canvasdata + '">';
          d3.select("#pngdataurl").html(pngimg);
          let photo = document.createElement("a");
          photo.download = "关系图.png";
          photo.href = canvasdata;
          document.body.appendChild(photo);
          photo.click();
        }
      } else {
        image.onload = function () {
          let photo = document.createElement("a");
          photo.download = "关系图.svg";
          photo.href = imgsrc;
          document.body.appendChild(photo);
          photo.click();
        }
      }
      // console.log(img); //此时是可以下载的svg了
      // console.log(imgsrc);//此时是可以打开svg了
      // 
      // console.log(d3.select("#svgdataurl").node().innerHTML);//此时是可以下载的svg了。
      // 上面和前面的实现是一样的


      // image.onload = function () {
      //   context.drawImage(image, 0, 0);
      //   let canvasdata = canvas.toDataURL("image/png");
      //   let pngimg = '<img src="' + canvasdata + '">';
      //   d3.select("#pngdataurl").html(pngimg);
      //   let photo = document.createElement("a");
      //   photo.download = "关系图.png";
      //   photo.href = canvasdata;
      //   document.body.appendChild(photo);
      //   photo.click();
      // }

      // })
    },
    getOptions (nodeclass) {
      return nodeclass == "影人" ? this.directors : this.movies
    },
    cancelForm () {
      this.loading = false;
      this.dialog = false;
    },
    async handleClose (done) {
      // alert(this.addRelation1_select)
      // alert(this.addRelation2_select)
      let type_id = null
      if (this.nodeclass1 == "影人" && this.nodeclass2 == "影片") {
        type_id = 2
      } else if (this.nodeclass1 == "影人" && this.nodeclass2 == "影人") {
        type_id = 1
      } else if (this.nodeclass1 == "影片" && this.nodeclass2 == "影人") {
        type_id = 3
      } else {
        type_id = 4
      };
      if (
        this.addRelation1_select != '' &&
        this.addRelation2_select > 0 &&
        this.relation_content.trim() != ""
      ) {
        if (this.loading) {
          return;
        }
        this.$confirm("确定要添加关系吗？")
          .then((_) => {
            this.loading = true;
            //逻辑
            service({
              url: "/figures/insert_relation",
              method: "post",
              data: {
                //传影人参数
                f_source: this.addRelation1_select,
                f_target: this.addRelation2_select,
                type_id: type_id,
                relationship: this.relation_content,
                relation_memo: this.relation_memo
              },
            }).then((response) => {
              if (response.data.msg == "Success") {
                this.$message({
                  message: "添加成功！",
                  type: "success",
                });
              } else {
                this.$message({
                  message: "添加失败！",
                  type: "warning",
                });
              }
            }).then(() => {
              if (this.nodeclass1 == "影人") {
                const keyJson = {
                  type: "figure"
                };
                keyJson.search = this.directors.filter((i) => i.fid == this.addRelation1_select)[0].f_name;
                this.keyJson = keyJson;
              } else {
                const keyJson2 = {
                  type: "movie"
                };
                keyJson2.search = this.movies.filter((i) => i.mid == this.addRelation1_select)[0].m_name;
                this.keyJson = keyJson2;
              }
              this.addRelation1_select = '';
              this.addRelation2_select = '';
              this.relation_content = '';
              this.relation_memo = '';
              // 动画关闭需要一定的时间
              this.loading = false;
              this.dialog = false;
              this.getData();
            });
          })
          .catch((_) => {
            this.dialog = false;
          });
      } else {
        this.$message({
          message: "输入信息或网络错误，请检查！",
          type: "warning",
        });
      }
    },
    // 匹配搜索建议
    query_search (query_string, callback) {
      let results = []
      let restaurants = this.search_option.map(i => i.f_name || i.m_name);
      // 影人匹配
      for (var i = 0; i < restaurants.length; i++) {
        if (restaurants[i].includes(query_string.trim().toLowerCase())) {
          results.push({ value: restaurants[i] });
        }
      }
      callback(results);
    },
    // 匹配项点击
    handle_select (item) {
      switch (this.select) {
        case "影人":
          // 影人搜索
          const keyJson = {
            type: "figure"
          };
          keyJson.search = item,
            this.keyJson = keyJson,
            this.getData()
          break;
        case "影片":
          // 影片搜索
          const keyJson2 = {
            type: "movie"
          };
          keyJson2.search = item,
            this.keyJson = keyJson2,
            this.getData()
          break;
        default:
          this.$message({
            message: "请选择要搜索的类别！",
            type: "warning",
          });

      }
    },
    serach_item_change (value) {
      this.search_content = ""
      switch (value) {
        // 影人搜索
        case "影人":
          this.search_option = this.directors;
          this.$refs.elinput.focus()
          break;
        case "影片":
          // 影片搜索
          this.search_option = this.movies;
          this.$refs.elinput.focus()
          break;
        default:
      }
    },
  },
  created () {
    this.keyJson={type:"figure",search:"费穆"}
    this.getData();
    // 获取影片名
    // this.axios.get(this.path + "/movies").then((response) => {
    //   for (var i = 0; i < response.data.m_data.length; i++) {
    //     this.movies.push(response.data.m_data[i]);
    //   }
    //   this.keyJson.type="movie";
    //   this.keyJson.search=this.movies[Math.floor(Math.random()*i)].m_name;
    //   // console.log(this.keyJson);
    //   this.getData()
    // });
    // //获取人物名
    // this.axios.get(this.path + "/figures").then((response) => {
    //   for (var i = 0; i < response.data.f_data.length; i++) {
    //     this.directors.push(response.data.f_data[i]);
    //   }
    //   //初始化this.search_option为随机影人
    //   this.search_option = this.directors;
    // });
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 0.7vw;
}

::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

#photo-download {
  position: absolute;
  left: 100%;
  transform: translate(-110%, 0);
  /* display: flex; */
}

.select_box {
  display: inline-block;
  width: 5vw;
  left:72%;
}

.select_box_input
 {
  display: inline-block;
  /* width: 10vw; */
}

.endForm-col {
  text-align: end;
}



.input_style {
  display: inline-block;
  width: 10vw;
  border-radius: 1vw;
  /* box-shadow: 4px 4px 10px #aaaaaa; */
  left:72%;
}

.serach_button_style {
  width: 5vw;
  color: #fff;
}

/* 控制滑块轨道颜色 */
input[type="range"] {
  -webkit-appearance: none; /* 去除默认的滑块样式 */
  width: 100px;
  height: 8px; /* 轨道的高度 */
  background: #ddd; /* 轨道的颜色 */
  border-radius: 5px; /* 轨道的圆角 */
  outline: none; /* 去除焦点时的轮廓 */
}

/* 控制滑块颜色 */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* 去除默认的滑块样式 */
  width: 15px; /* 滑块的宽度 */
  height: 15px; /* 滑块的高度 */
  background: #E2A253; /* 滑块颜色 */
  border-radius: 50%; /* 圆形滑块 */
  cursor: pointer; /* 鼠标指针 */
}
</style>