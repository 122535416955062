<template >
  <!-- 底部第一行容器 -->
  <el-card
   class="word-cloud"
   :body-style="{ padding: '10px' }"
  >
    <div v-show="window_width<768" align='left' style="font-weight:bold;color:#e2a253;margin-bottom:2.5vw;font-size:15px;margin:-1.5vw 0 2vw -0.5vw">影人词云图</div>
    <svg id="wordcloud" height="100%"></svg>
  </el-card>
</template>

<script>
import * as d3 from "d3";
import * as cloud from "d3-cloud";
import * as $ from "jQuery";
import { loadHistory } from "../../../utils/store";

export default {
  components: {},
  name: "PicCloud",
  props: {
  },
  data () {
    return {
      words: [],
      local_words:[],
      //   fontSizeMapper: (word) => Math.log2(word.value) * 5,
      path: "https://api.movie.yingshinet.com/chronicles",
      wordcloud: null,
      wordcount: 0,
      wordsdata: [],
      director: "",
      figures:[],
      figure_count:0,
      window_width:window.screen.width,
    };
  },
 watch: {
  //监听相同路由下参数变化的时候，从而实现异步刷新
            '$route'(to,from) {
                this.$router.go(0)
            },
        }, 
  methods: {
    // 给每个词加搜索功能
    initWordCloud () {
      // 先根据this.words绘图
      this.drawWordCloud();
      // 绘图后根据添加的类名，给每个词添加搜索功能
      this.$nextTick(function () {
        document.querySelectorAll(".cloud-word").forEach((item) => {
          item.addEventListener("click", () => {
            // 词云图词汇内容
            let keyword = item.textContent;
            this.$router.push("/directors?=" + keyword);
          });
        });
      });
    },
    //获得初始数据

    getEvents () {
      //  const jsonFilePath = "/static/homepage_cloud.json";
      // // 发送GET请求以获取本地JSON数据
      // this.axios.get(jsonFilePath).then((response) => {
      //   console.log(response.data);
      //   this.local_words=response.data.data;
      //       // console.log(this.local_words);
      //     this.initWordCloud();
      // });
           this.axios.post("https://api.movie.yingshinet.com/chronicles/cloud", {})
          .then(response => {
           console.log(response.data);
           this.local_words=response.data.data;
           console.log(this.local_words);
           this.initWordCloud();
          })
    
 
       },

    drawWordCloud () {
      let w = $(".word-cloud").width();
      let h = $(".word-cloud").height();
      let layout = cloud()
        .size([w, h])
        .words(this.local_words)
        .padding(3)
        .rotate(0)
        .font("Impact")
        .fontSize(function (d) {
          return Math.sqrt(d.count)+10;
        })
        .on("end", draw);

      layout.start();

      function draw (words) {
        const color = d3.scaleOrdinal(d3.schemeCategory10);
        let svg = d3.select("#wordcloud");
        svg.selectAll("*").remove();
        svg.attr("width", layout.size()[0]).attr("height", layout.size()[1]);
        let g = svg
          .append("g")
          .attr(
            "transform",
            "translate(" +
            layout.size()[0] / 2 +
            "," +
            layout.size()[1] / 2 +
            ")"
          );
        let text = g
          .selectAll("text")
          .data(words)
          .enter()
          .append("a")
          .attr("class", "cloud-word")
          .append("text")
          .attr("fill", (_, i) => color(i))
          .attr("transform", function (d) {
            return (
              "translate(" +
              [Math.random() * 1000 - 500, Math.random() * 1000 - 500] +
              ")"
            );
          })
          .transition()

          .style("font-size", function (d) {
            return d.size + "px";
          })
          .style("font-family", "SimSun")
          .attr("text-anchor", "middle")
          .text(function (d) {
            return d.figure;
          })
          .attr("transform", function (d) {
            return (
              "translate(" +
              [d.x, d.y] +
              ")rotate(" +
              d.rotate +
              ")" +
              "scale(1.2)"
            );
          });
      }
    },
  },
  created()
  {

      this.getEvents();

  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-size: 0.7vw;
}
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

.el-carousel__indicators li {
  border: 0.5vw;
}
.el-card__body {
  padding: 0.5vw;
}
.footerCard {
  margin: 0.5vw;
  margin-left: 0.5vw;
}
</style>

<style>
.cloud-word:hover {
  cursor: pointer;
}
.footerCard .el-card__body {
  padding: 0.5vw;
}
</style>