import { render, staticRenderFns } from "./force.vue?vue&type=template&id=5d525d27&scoped=true"
import script from "./force.vue?vue&type=script&lang=js"
export * from "./force.vue?vue&type=script&lang=js"
import style0 from "./force.vue?vue&type=style&index=0&id=5d525d27&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d525d27",
  null
  
)

export default component.exports