<template>
  <div :class="this.$store.state.isCollapse ? 'container_collapse' : 'container'" style="background-color: #3C3C3C;">
    <el-row class="top-section">
      <video-player class="video-player vjs-custom-skin" ref="VideoPlayer" :options="playerOptions"
        @ready="playerReadied"></video-player>
    </el-row>
    <!-- <el-row class="top-section">
      <div class="top-section-text">
        &nbsp;&nbsp;由北京大学艺术学院与中国传媒大学文化大数据实验室合作开发的中国电影知识体系平台，基于批判性的数字人文视野，试图在平台革命的宏观趋势中深入考察从数据库到数字平台的电影研究路径，通过知识论、知识管理学与知识情境分析的研究框架，跟主体性的价值观、整体性的组织方式与具体化的实际应用结合在一起，以“影人年谱”“电影计量”“知识生成”“电影百科”“文献索引”等五个特色数据库为中心，搭建一个学术导向、优特数据、众包群智与开源共享的、具有思辨性和成长性的中国电影学术平台与中国电影数字基础设施，并力图与中国电影的史论研究、学术创新以及公益服务互动共生，为反思中国电影实践、推进中国电影学派并构建中国电影知识体系奠定必要的基础。
        <br><br>
        &nbsp;&nbsp;The China Cinema Knowledge System Platform, jointly developed by the School of Arts at Peking University and the Cultural Big Data Laboratory at the Communication University of China, is grounded in a critical digital humanities perspective. It endeavors to delve into the film research pathways from databases to digital platforms within the broader trend of platform revolutions. By integrating epistemology, knowledge management, and contextual analysis of knowledge within a framework that emphasizes subjectivity, holistic organization, and concrete application, the platform centers around five distinctive databases: "Film Figures Chronology," "Film Metrics," "Knowledge Generation," "Film Encyclopedia," and "Literature Index." This initiative aims to construct an academically oriented, data-rich, crowdsourced, open-source, and critically reflective academic platform and digital infrastructure for Chinese cinema. It aspires to interact and co-evolve with the historical and theoretical research, academic innovation, and public service endeavors of Chinese cinema, thereby laying a necessary foundation for reflecting on Chinese film practices, advancing the Chinese Film School, and constructing the Chinese Film Knowledge System.
      </div>
    </el-row> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src: this.$store.state.login_state ? "https://assets.yingshinet.com/chronicles/videos/introduction.mp4" : "https://assets.yingshinet.com/chronicles/videos/introduction_720.mp4",
          },
        ],
        poster: "/static/home_background.png",
        notSupportedMessage: "暂时没有此视频资源，请观看《人生》、《联华交响曲》、《城市之夜》、《狼山喋血记》、《天伦》、《香雪海》、《生死恨》",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true,
          volumePanel: {
            inline: false,
          }
        },
      },
    };
  },

  methods: {
    playerReadied(player) {
      console.log("播放器已就绪", player);
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.body {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-top: 0;
}

.top-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-player {
  width: 80%;
  height: auto;
}

.container_collapse {
  padding-left: 8%;
  padding-right: 8%;
}

.top-section-text {
  position: relative;
  font-size: 1.1vw;
  width: 80%;
  height: auto;
  margin-top: 8vh;
  font-family: "汉仪中简宋", HanYiZhongJianSong, serif;
  /* 使用楷体字体，如果用户设备中没有楷体，会回退到系统中的默认衬线字体 */
  color: #e2a253;
}
</style>
