<template>
<el-card :body-style="{ padding: '10px' }">
  <el-container v-if="moviedata">
          <!-- <hr /> -->
          <el-container direction="vertical">
            <!-- <div>热门推荐</div> -->
            <el-row :gutter="20">
              <el-col
                :xs="6"
                :sm="6"
                :md="6"
                :lg="4"
                :xl="4"
                v-for="(data, i) in showData"
                :key="i"
                class="mouse_hover"
                style="height:100%"
              >
                <el-card class="card"  :body-style="{ padding: '0px' }">
                  <img :src="data.pic" class="image" fit="fill" @click="convertmovie(data.no)" />
                  <div class="clearfix" style="height:10%;">
                    <div class="time">{{ data.name }}</div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </el-container>
  </el-container>
  </el-card>
</template>

<script>
import BScroll from "better-scroll";
export default {
  data() {
    return {
      window_width:window.screen.width,
      drawer_add: false,
      drawer: false,
      direction: "btt",
      colors: ["#b8860b", "#778899"],
      currentDate: new Date(),
      directors: [],
      movie: [],
      moviedata: {},
      // videoid:1,
      movie_id: 12,
      tableData: [],
      showData: [],
      src: "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
    };
  },

  methods: {
    getInfo() {
      this.axios
      .post("https://api.movie.yingshinet.com/movies/new",{
        request_num:6,
      })
      .then((response) => {
        this.work=JSON.parse(JSON.stringify(response.data)).m_data;
        this.num=response.data.m_count;
        this.getData();
      });
    },
    getData() {
      for (var i = 0; i < this.num; i++) {
        this.tableData[i] = {};
        this.tableData[i].no = this.work[i].mid;
        this.tableData[i].name = this.work[i].m_name;
        this.tableData[i].pic =
          this.work[i].m_pic ||
          "https://assets.yingshinet.com/chronicles/pictures/MOVIE_27.jpg";
        this.tableData[i].director = this.work[i].m_director;
        this.tableData[i].actor = this.work[i].m_actor;
        this.tableData[i].year = this.work[i].m_year;
        this.tableData[i].script = this.work[i].m_script;
        this.tableData[i].douban_rank = this.work[i].douban_rank;
        this.tableData[i].intro = this.work[i].m_intro || "简介" + i + "待补充";
        this.tableData[i].video_path =
          this.work[i].m_video ||
          "https://assets.yingshinet.com/chronicles/videos/TL_FM.mp4";
      }
      this.showData = this.tableData;
    },
    convertmovie(id) {
      if(this.window_width<768){
        this.$message({
          showClose:true,
          message:'更多信息请见电脑端页面',
          type:'warning'
        });
      }
      this.$router.push({
          path: "/video",
          query: {
            id: id,
          },
        });
        location.reload();
    },
  },
  created() {
    this.getInfo();
    // this.axios
    //   .post("https://api.movie.yingshinet.com/movies/select", {
    //     mid: this.$route.query.id,
    //   })
    //   .then((response) => {
    //     // this.moviedata = response.data.m_data[0];
    //     // console.log("moviedata: "+this.moviedata)
    //     // this.$set(this.moviedata);
    //     this.getInfo();
    //   });
  },

}
</script>
<style scoped>




.card {
  width:100%;
  height:100%;
}

.time {
  font-size: 13px;
  color: #F0EBE1;
  text-align: center;
}



.image {
  display: block;
  height:120px
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.mouse_hover:hover {
  cursor: pointer;
}

::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
.el-card {
  background-color: #606266;
  border: 1px solid #a1a3a8;
}

.el-container {
  background-color: unset;
}
</style>